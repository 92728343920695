
import Vue from "vue"
import SubscriptionMaritalStatus from "@/components/subscription/SubscriptionMaritalStatus.vue"
import SubscriptionPreparation from "@/components/subscription/SubscriptionPreparation.vue"
import SubscriptionContactDetails from "@/components/subscription/SubscriptionContactDetails.vue"
import SubscriptionConfirmation from "@/components/subscription/SubscriptionConfirmation.vue"
import SubscriptionDocuments from "@/components/subscription/SubscriptionDocuments.vue"
import AlertError from "@/components/AlertError.vue"
import SubscriptionMixin from "@/mixins/SubscriptionMixin.vue"

export default Vue.extend({
  name: "Subscription",
  components: {
    SubscriptionPreparation,
    SubscriptionContactDetails,
    SubscriptionMaritalStatus,
    SubscriptionConfirmation,
    SubscriptionDocuments,
    AlertError
  },
  mixins: [SubscriptionMixin]
})
