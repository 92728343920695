
import Vue from "vue"
import AlertInfo from "@/components/AlertInfo.vue"

import DatePicker from "@/components/DatePicker.vue"

export default Vue.extend({
  name: "SubscriptionMartialStatus",
  components: {
    AlertInfo,
    DatePicker
  },
  props: {
    bodyRequest: Object,
  },
  data: () => ({
    birth_date: null as null | string,
    birth_city: "",
    birth_country: "",
    nir: "",
    dirtyForm: false,
    emptyRule: [],
    dateRule: [],
    NIRregex: /^(?<sex>[1-27-8])(?<birth_year>[0-9]{2})(?<birth_month>0[0-9]|[2-35-9][0-9]|[14][0-2])(?<place>(?<metropole_dept>0[1-9]|[1-8][0-9]|9[0-6])(?<metropole_city>00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(?<corse>2[abAB])(?<corse_city>00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(?<foreign_code>99)(?<foreign_country>00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(?<oversea_dept>9[78][0-9])(?<oversea_city>0[1-9]|[1-8][0-9]|90))(?<order>00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(?<nir_key>0[1-9]|[1-8][0-9]|9[0-7])$/i,
    maxBirthDate: "",
  }),
  created: function() {
    let date = new Date()
    date.setFullYear(date.getFullYear() - 18)
    this.maxBirthDate = date.toISOString().substr(0, 10)
  },
  computed: {
    birthPlaceRules() {
      const rules = []
      if (this.birth_city) {
        const rule = (v: string) =>
          (v.length > 1 && v.length < 254) ||
          this.$vuetify.lang.t("$vuetify.invalid_length")
        rules.push(rule)
      }
      return rules
    },
    birthCountryRules() {
      const rules = []
      if (this.birth_country) {
        const rule = (v: string) =>
          (v.length > 1 && v.length < 254) ||
          this.$vuetify.lang.t("$vuetify.invalid_length")
        rules.push(rule)
      }
      return rules
    }
  },
  watch: {
    birthDateFormat(val: string | null) {
      if (!val) {
        this.birth_date = null
      }
    }
  },
  methods: {
    previousStep() {
      this.$emit("step", 2)
      this.$emit("stepTitle", "$vuetify.contact_details")
    },
    validateStep() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        const inputs = ["birth_date", "birth_city", "birth_country"]
        inputs.forEach((document) => {
          delete this.bodyRequest[document]
          if (this.$data[document] !== null && this.$data[document] !== "") {
            this.bodyRequest[document] = this.$data[document].trim()
          }
        })
        delete this.bodyRequest["nir"]
        if (this.$data["nir"] !== null && this.$data["nir"] !== "") {
          this.bodyRequest["nir"] = this.$data["nir"].replace(/\s/g, '')
        }
        this.$emit("step", 4)
        this.$emit("stepTitle", "$vuetify.documents")
      }
    },
    updateBirthDate(birth_date: string): void{
      this.birth_date = birth_date
    }
  },
})
